#product-form-div {
  padding: 20px;
}
#create-product-form {
  border: 1px solid black;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  text-align: justify;
  gap: 10px;
}

#create-product-form div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#create-product-form div label {
  width: 20%;
}

#create-product-form input {
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  width: 40%;
}

#create-product-form textarea {
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  width: 40%;
}

.product-create-btn {
  padding: 15px;
  background-color: green;
  color: white;
  width: fit-content;
  margin: auto;
  border-radius: 10px;
}
