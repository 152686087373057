#admin-login {
  height: 900px;
}
#admin-login > h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  font-size: xx-large;
  margin: 2% auto;
  color: rgb(72, 170, 72);
  text-align: center;
  width: fit-content;
  padding: 1%;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.admin-validation-form {
  display: flex;
  flex-direction: column;
  margin: 5% auto;
  width: 50%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 10px;
}

.admin-validation-form > input {
  border: 1px solid grey;
  margin: 2% auto;
  padding: 10px;
  border-radius: 10px;
  width: 50%;
}

.admin-validation-form > .form-submit-btn {
  border: 1px solid grey;
  width: 60%;
  margin: 5% auto;
  padding: 20px;
  border-radius: 10px;
  color: white;
  background-color: green;
}

.checkbox-container {
  display: flex;
  align-items: center;
  width: 48%;
  margin: auto;
  gap: 5px;
  align-items: center;
}

.show-password {
  cursor: pointer;
}

label {
  font-size: 18px;
}

@media only screen and(max-width:"1024px") {
  #admin-login {
  }
}
