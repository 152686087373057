#construction {
  background: url("./BG.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 50px;
  gap: 25%;
}

#construction > .construction-first-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

#construction > .construction-first-row > div {
  margin-top: 100px;
}

#construction > .construction-first-row > div > p:first-child {
  font-family: "Inter Tight";
  font-size: 39.56px;
  font-weight: 400;
  line-height: 47.86px;
  text-align: left;
  text-decoration-skip-ink: none;
  width: 50%;
  margin-left: 12%;
}

#construction > .construction-first-row > div > p:first-child > span {
  color: #004dc0;
  font-family: "Inter Tight";
  font-size: 39.56px;
  font-weight: 700;
  line-height: 47.86px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

#construction > .construction-first-row > div > p:last-child {
  font-family: "Inter Tight";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
  color: #000000;
  text-decoration-skip-ink: none;
  width: 50%;
  margin-left: 12%;
  margin-top: 10px;
}

#construction > .construction-second-row > p:first-child {
  font-family: "Inter Tight";
  font-size: 32px;
  font-weight: 400;
  line-height: 38.72px;
  text-align: center;
}

#construction > .construction-second-row > p:last-child {
  font-family: "Inter Tight";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: center;
  text-decoration-skip-ink: none;
}

@media all and (max-width: 700px) {
  #construction > .construction-first-row {
    flex-direction: column;
  }

  #construction > .construction-first-row > div > p:first-child {
    font-size: 29.56px;
    line-height: 30.86px;
  }

  #construction > .construction-first-row > div > p:first-child > span {
    font-size: 29.56px;
    line-height: 30.86px;
  }

  #construction > .construction-first-row > div > p:last-child {
    font-family: "Inter Tight";
    font-size: 15px;
    line-height: 14.2px;
  }

  #construction > .construction-second-row > p:first-child {
    font-size: 20px;
    line-height: 28.72px;
  }

  #construction > .construction-second-row > p:last-child {
    font-size: 15px;
    line-height: 14.2px;
  }
}

@media all and (max-width: 490px) {
  #construction > .construction-first-row > div > p:first-child {
    font-size: 19.56px;
    line-height: 20.86px;
  }

  #construction > .construction-first-row > div > p:first-child > span {
    font-size: 19.56px;
    line-height: 20.86px;
  }

  #construction > .construction-first-row > div > p:last-child {
    font-family: "Inter Tight";
    font-size: 15px;
    line-height: 14.2px;
  }

  #construction > .construction-second-row > p:first-child {
    font-size: 13px;
    line-height: 18.72px;
  }

  #construction > .construction-second-row > p:last-child {
    font-size: 14px;
    line-height: 14.2px;
  }
}
