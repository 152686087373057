@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");

#main-accordion {
  grid-template-columns: repeat(2, 1fr);
  width: 85%;
  margin: auto;
  gap: 21px;
  margin-top: 30px;
  margin-bottom: 30px;
}

#main-accordion .accordion-box:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.accordion-button:hover {
  background: none !important;
}

.question {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
  color: #0e0e0e;
}

.answer {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: (255, 255, 255, 0.9);
  text-align: left;
}

@media screen and (max-width: 490px){
  #main-accordion {
    grid-template-columns: repeat(1, 1fr);
    width: 85%;
    margin: auto;
    gap: 21px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}