#relative > div {
  margin-top: 20px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}

#item {
  background-color: #f2f2f2;
  padding: 5px;
  font-family: "Inter Tight";
  font-size: 13px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-align: left;
  cursor: pointer;
  padding: 6px;
}

#item:hover {
  background-color: black;
  color: #fff;
}
