@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:wght@400;500;600&display=swap");

#dashboard_product_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-top: 15px;
}

#dashboard_product_details > .product_details_card {
  background: url("./12.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0e0f10f2;
  border: 1px solid #0e0f10;
  width: 50%;
  padding: 36px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.card-top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffff;
  margin-top: 10px;
}

.card-top-row > p {
  font-family: "Inter Tight";
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  letter-spacing: 0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.card-top-row img {
  width: 30px;
}

.card-mid-row {
  background: linear-gradient(
    90deg,
    rgba(188, 31, 38, 0) 0%,
    rgba(188, 31, 38, 0.5) 100%
  );
  border-radius: 3px;
}

.card-mid-row > p {
  font-family: "Inter Tight";
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: right;
  padding: 6px;
  text-decoration-skip-ink: none;
  color: #ffffff;
  padding-right: 20px;
}

#card-last-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-details {
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
}
.card-details > p {
  color: #ffffff;
  font-family: "Inter Tight";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-decoration-skip-ink: none;
}
.card-details > p > span {
  color: #f2f2f2;
  font-family: "Inter Tight";
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  text-decoration-skip-ink: none;
}

.card-details > .tenure-xirr {
  display: flex;
  align-items: center;
  gap: 3px;
}

.card-details > .tenure-xirr > p {
  color: #f2f2f2;
  font-family: "Inter Tight";
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  text-decoration-skip-ink: none;
}

.investment-btn-card {
  width: 50%;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.investment-btn-card > .heading {
  color: #0e0f10;
  font-family: "Inter Tight";
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: 0.02em;
  text-align: left;
  text-decoration-skip-ink: none;
}

.btn-card-mid {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.btn-card-mid > p:first-child {
  background-color: #f2f2f2;
  width: 20%;
  padding: 15px;
  text-align: center;
  font-family: "Inter Tight";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-decoration-skip-ink: none;
  color: #191a1c;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.btn-card-mid > p:last-child {
  font-family: "Inter Tight";
  font-size: 20px;
  font-weight: 500;
  padding: 15px;
  line-height: 24px;
  text-decoration-skip-ink: none;
  width: 80%;
  text-align: center;
  background-color: #f2f2f2;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  margin: auto;
}

.investment-btn-card > button {
  background-color: #191a1c;
  color: #ffffff;
  font-family: "Inter Tight";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-decoration-skip-ink: none;
  padding: 15px;
  border-radius: 8px;
}

.mid-section {
  border: 1px solid #f2f2f2;
  margin-top: 40px;
  border-radius: 8px;
  padding: 32px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mid-section-content {
  display: flex;
  gap: 20px;
  justify-content: baseline;
  align-items: center;
}

.mid-section-content-right {
  display: flex;
  flex-direction: column;
}

.mid-section-content-right > div {
  display: flex;
  align-items: center;
  gap: 3px;
  color: #757677;
  font-family: "Inter Tight";
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  text-decoration-skip-ink: none;
}

.mid-section-content-right > p {
  font-family: "Inter Tight";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-decoration-skip-ink: none;
  color: #191a1c;
}

.mid-section-bottom {
  width: 100%;
  border: 1px solid #f2f2f2;
  padding: 6px;
  border-radius: 8px;
  margin-top: 40px;
}

.mid-section-bottom > .mid-section-btm-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mid-section-bottom > .mid-section-btm-col > button {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 50%;
  padding: 12px 8px;
}

.mid-section-bottom > .mid-section-btm-col > button:hover {
  background-color: #f2f2f2;
}

.mid-section-bottom > .mid-section-btm-col > button > p {
  font-family: "Inter Tight";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: 0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.corestrength-possiblechallenges {
  margin-top: 10px;
}

.corestrength-possiblechallenges li {
  padding: 12px 8px;
  color: #0e0f10;
  border-bottom: 1px solid #f2f2f2;
  text-align: left;
  display: flex;
  gap: 10px;
}

.corestrength-possiblechallenges li > img {
  padding: 5px;
  border-radius: 50%;
  background-color: #f2f2f280;
}

.bottom-section-documents {
  padding: 24px;
  border: 1px solid #f2f2f2;
  margin-top: 40px;
  border-radius: 8px;
  width: 100%;
}

.bottom-section-documents p {
  color: #0e0f10;
  font-family: "Inter Tight";
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  text-decoration-skip-ink: none;
}

.bottom-section-documents .bottom-section-row {
  margin-top: 15px;
  display: flex;
  width: 100%;
  gap: 20px;
}

.bottom-section-documents .bottom-section-row > div {
  padding: 18px 24px;
  border-radius: 8px;
  background-color: #f2f2f2;
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottom-section-documents .bottom-section-row > div p {
  font-family: "Inter Tight";
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: 0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.bottom-section-documents .bottom-section-row > div > div {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.bottom-section-documents .bottom-section-row > div > div > p:nth-child(2) {
  gap: 6px;
  font-family: "Inter Tight";
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #757677;
}

.bottom-section-documents .bottom-section-row img {
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  max-width: 90%;
  height: auto;
  z-index: 10000;
}

.modal-content > p:first-child {
  color: #0e0f10;
  font-family: "Inter Tight";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-decoration-skip-ink: none;
}

.modal-content > p:last-child {
  color: #757677;
  margin-top: 10px;
  font-family: "Inter Tight";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: 0.02em;
  text-align: left;
  text-decoration-skip-ink: none;
}

.modal-content-unique {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(26, 25, 25);
  padding: 20px;
  border-radius: 10px;
  width: 480px;
  max-width: 90%;
  height: auto;
  height: 200px;
  z-index: 10000;
  text-align: center;
}

.modal-content-unique > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.modal-content-unique > div > p:first-child {
  color: #f2f2f2;
  font-family: "Inter Tight";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-decoration-skip-ink: none;
}

.modal-content-unique > p {
  color: white;
  margin-top: 10px;
  font-family: "Inter Tight";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: 0.02em;
  text-align: left;
  text-decoration-skip-ink: none;
}
