.product-list ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
  padding: 10px;
}

.product-list li {
  border: 1px solid black;
  border-radius: 20px;
  padding: 20px;
  text-align: left;
  color: #ffff;
  background-color: rgba(59, 51, 59, 0.822);
}

.product-list .name-logo {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}

.product-list li > .edit-delete-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.product-list li > .edit-delete-button > button:nth-child(1) {
  background-color: blue;
  padding: 10px 20px;
  border-radius: 10px;
  color: white;
  font-weight: 500;
  font-size: 20px;
}

.product-list li > .edit-delete-button > button:nth-child(2) {
  background-color: red;
  padding: 10px;
  border-radius: 10px;
  color: white;
  font-weight: 500;
  font-size: 20px;
}

@media only screen and (max-width: 981px) {
  .product-list ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin-top: 20px;
    padding: 10px;
  }
}
