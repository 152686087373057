@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:wght@400;600&display=swap");

#dashboard-content {
  padding: 4% 6%;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

#dashboard-content > .dashboard-left {
  flex-basis: 33%;
  padding-right: 20px;
  padding-bottom: 20px;
  border-right: 0.5px solid #f2f2f2;
}

#dashboard-content > .dashboard-right {
  flex-basis: 67%;
  padding-left: 25px;
  padding-bottom: 20px;
  border-left: 0.5px solid #f2f2f2;
}

#dashboard-content > .dashboard-left > p {
  font-family: "Inter Tight";
  font-size: 24px;
  font-weight: 900;
  line-height: 28.8px;
  text-align: left;
  color: #0e0f10;
  text-decoration-skip-ink: none;
}

/* Scroll Bar Start */
.custom-scrollbar {
  max-height: 800px;
  height: 690px;
  overflow-y: auto;
  margin-top: 20px;
  padding-right: 15px;
  width: 100%;
  box-sizing: border-box;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 8px;
  min-height: 30px;
  cursor: default;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
/* Scroll Bar End */

#dashboard-content > .dashboard-right > .pointer-logo {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #f2f2f2;
  justify-content: left;
  align-items: center;
}

#dashboard-content > .dashboard-right > .pointer-logo p {
  font-family: "Inter Tight";
  font-size: 32px;
  font-weight: 900;
  line-height: 38.4px;
  text-align: left;
  text-decoration-skip-ink: none;
}

#dashboard-content > .products {
  padding-bottom: 30px;
  border-bottom: 1px solid #b9d1f3;
  width: 100%;
}

#dashboard-content > .products > h1 {
  color: #012d65;
  font-family: "Inter Tight";
  font-size: 32px;
  font-weight: 500;
  line-height: 38.4px;
  text-align: left;
  text-decoration-skip-ink: none;
  padding: 3% 0%;
}



.dashboard-footer {
  background-color: #ffff;
  /* margin: 5%; */
  padding: 4% 6%;
  background-color: #0e0f10;
}

.dashboard-footer > .dashboard-footer-content > p {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 700;
  line-height: 32px;
  text-align: justify;
  color: #aaa;
}

.dashboard-footer > .dashboard-footer-content span {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-align: justify;
}

@media screen and (max-width: 1024px) {
  .help-btn-div {
    padding: 15px;
  }
}

@media screen and (max-width: 900px) {
  .help-btn-div {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  #dashboard-content > .dashboard-top {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
  }
}
