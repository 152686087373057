@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:wght@400;600&display=swap");

#navbar-container {
  width: 100%;
  padding: 2% 6%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #b9d1f3;
  background: url("./BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0e0f10;
}

.login-logout-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 6px;
  align-items: center;
  background-color: #0e0f10;
  color: #ffff;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
}

.login-logout-btn > div > button {
  font-family: "Inter Tight";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  text-decoration-skip-ink: none;
}
