@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:wght@400;600&display=swap");

#dashboard-products {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin: auto;
  margin-top: 20px;
}

#product-card {
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  background-color: #fdfdfd;
  cursor: pointer;
}

#product-card > .card-bg {
  background-image: url("./image\ 8.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  background-blend-mode: difference;
  background-color: #fafafa;
}

.card-bg {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.card-bg > .name-logo {
  display: flex;
  padding: 20px 30px;
  margin-top: 30px;
  text-align: left;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.card-bg > h1 {
  font-family: "Inter Tight";
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  height: fit-content;
  width: 50%;
  color: #0e0f10;
}

.card-bg > p {
  padding: 6px;
  text-align: center;
  width: 50%;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 60px;
  height: fit-content;
  font-family: "Inter Tight";
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.infinity-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
