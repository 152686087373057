@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap");

:root {
  --primary-color: rgba(233, 53, 63, 1);
  --secondary-color: rgb(243, 28, 39);
}

#container {
  background: url("./join_the_club.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  padding-bottom: 100px;
}

#container > .top-heading {
  color: rgba(173, 173, 174, 1);
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  text-align: center;
  padding: 45px;
}

#container > .heading {
  text-align: left;
  padding: 90px 0 156px 90px;
  margin-top: -17px;
}

#container > .heading > .logo {
  width: 130px;
  padding: 15px 0;
  border-bottom: 2px solid rgba(233, 53, 63, 1);
}

.heading > .above-button {
  color: rgba(254, 254, 254, 1);
  font-family: "Inter";
  font-weight: 500;
  font-size: 56px;
  line-height: 67.77px;
  margin-top: 30px;
  width: 70%;
}

.heading > .input-button {
  border: 1px solid rgba(23, 23, 23, 1);
  background-color: rgba(30, 30, 30, 1);
  width: 100%;
  width: fit-content;
  border-radius: 8px;
  color: #656261;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  padding: 4px 4px 4px 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 80px;
}

.heading > .input-button > input {
  color: rgba(255, 255, 255, 1);
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  background-color: rgba(30, 30, 30, 1);
  border-style: none;
  border-radius: 1px;
  height: 50px;
}

.input-button {
  position: relative;
  display: flex;
  align-items: center;
}

.input-button::before {
  color: #ccc;
  content: "+91 |";
  position: absolute;
  left: 10px;
  font-size: 16px;
  pointer-events: none;
}

.input-button input {
  padding-left: 35px;
  font-size: 16px;
  height: 40px;
  width: 250px;
  border: 1px solid #999;
  border-radius: 5px;
  padding: 16px 32px;
}

.input-button button {
  margin-left: 10px;
  width: 40%;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

input:focus {
  outline: none;
  border: none;
}

.heading > .input-button > button {
  background-color: var(--primary-color);
  color: rgba(255, 255, 255, 1);
  border-radius: 53px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  text-align: center;
  padding: 18px 48px;
  border-style: none;
}

.heading > .input-button > button:active {
  background-color: var(--secondary-color);
}

.heading > .earn-text {
  color: rgba(173, 173, 174, 1);
  width: fit-content;
  padding: 20px 20px 20px 0;
}

#portfolio {
  width: 85%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: -30px;
  gap: 24px;
  background-color: transparent;
}

#portfolio > .investment-portfolio {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 32px 0;
  border-radius: 16px;
  gap: 16px;
  background-color: rgba(18, 18, 18, 0.99);
}

#portfolio > .investment-portfolio > p {
  font-family: "Inter";
  font-weight: 500;
  font-size: 20px;
  line-height: 24.2px;
  text-align: center;
  background-color: transparent;
  padding: 0 5px;
  color: rgba(222, 222, 223, 1);
  padding-bottom: 3%;
  border-bottom: 1px solid rgba(33, 33, 33, 1);
}

.investment-portfolio > .portfolio-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.investment-portfolio > .portfolio-table > .portfolio-col {
  background-color: transparent;
  padding: 15px 36px;
  border-right: 1px solid rgba(98, 22, 26, 1);
  border-radius: 25px;
}

.investment-portfolio:last-child > .portfolio-table {
  padding: 12.8px 0;
}

.investment-portfolio > .portfolio-table > .portfolio-col > p {
  background-color: transparent;
  color: rgba(222, 222, 223, 1);
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: center;
}

.investment-portfolio > .portfolio-table > .portfolio-col > .num {
  font-family: "Inter" sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 33.88px;
  text-align: center;
}

.investment-portfolio:first-child
  > .portfolio-table
  > .portfolio-col:nth-child(3) {
  border: none;
  margin-right: -52px;
}

.investment-portfolio > .portfolio-table > .portfolio-col:last-child {
  border: none;
}

@media only screen and (max-width: 768px) {
  #portfolio {
    flex-direction: column;
  }

  .heading > .input-button {
    width: 80%;
    gap: 80px;
  }

  .heading > .input-button > input {
    width: 40%;
  }

  .heading > .input-button > button {
    font-size: 12px;
  }

  input::-webkit-input-placeholder {
    color: transparent;
  }
}

@media only screen and (max-width: 665px) {
  #portfolio {
    flex-direction: column;
    text-wrap: wrap;
  }

  #portfolio > .investment-portfolio > .portfolio-table {
    flex-direction: column;
    padding: 0;
    text-wrap: wrap;
  }

  .investment-portfolio > .portfolio-table > .portfolio-col {
    background-color: transparent;
    padding: 24px 24px;
    border-right-style: none;
    border-bottom: 1px solid rgba(98, 22, 26, 1);
    border-radius: 25px;
  }

  .investment-portfolio:first-child
    > .portfolio-table
    > .portfolio-col:nth-child(3) {
    border: none;
    margin-right: 0;
  }

  #portfolio > .investment-portfolio:first-child {
    padding-left: 14px;
    padding-right: 14px;
  }
  #second-section > .heading-block {
    background: none;
  }
}

/* mobile view ----> */
@media only screen and (max-width: 430px) {
  #container {
    padding: 20px;
  }
  #container > .top-heading {
    font-size: 8px;
    line-height: 9.68px;
    text-align: center;
    padding: 22.5px;
  }

  #container > .heading {
    text-align: left;
    padding: 86px 0 86px 0;
    margin-top: -17px;
  }

  .heading > .above-button {
    color: rgba(254, 254, 254, 1);
    font-family: "Inter";
    font-weight: 500;
    font-size: 45.25px;
    line-height: 54.76px;
    margin-top: 30px;
    width: 70%;
  }

  .heading > .input-button > input {
    width: 65%;
    font-size: 10px;
    padding: 15px;
  }

  .input-button::before {
    font-size: 10px;
  }
  .heading > .input-button {
    border: 1px solid rgba(23, 23, 23, 1);
    background-color: rgba(30, 30, 30, 1);
    width: 100%;
    border-radius: 8px;
    color: #656261;
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    border-radius: 8px;
    line-height: 5.75px;
    gap: 0;
  }

  .input-button > input::placeholder {
    color: transparent;
  }
  .input-button > button {
    border-radius: 38.11px;
    padding: 15px !important;
  }
}

@media only screen and (max-width: 345px) {
  #portfolio > .investment-portfolio:first-child {
    padding-left: 14.5px;
    padding-right: 14.5px;
  }
}
