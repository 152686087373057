#admin-dashboard > h1 {
  font-size: 32px;
  font-weight: bolder;
  margin: 2% auto;
}

.product-handle-button {
  margin: 2%;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
  align-items: top;
  padding: 20px;
}

.product-handle-button > button {
  /* border: 1px solid black; */
  height: fit-content;
  padding: 20px;
  border-radius: 10px;
  background-color: rgb(128, 0, 0);
  color: antiquewhite;
  font-size: medium;
  font-weight: 500;
}

.product-handle-button > button:nth-child(2) {
  background-color: blueviolet;
}

.product-form > h2 {
  font-size: 28px;
  font-weight: 500;
}

.product-list > h2 {
  font-size: 28px;
  font-weight: 500;
}
