@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap");

#login {
  display: flex;
  width: 100%;
}

#login > .left-login {
  background-color: black;
  position: relative;
  flex-basis: 40%;
  height: 100vh;
  background-image: url("../login/fractionedLogin.svg");
  background-position-y: center;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

#login > .left-login > img {
  position: relative;
  z-index: 1;
  top: 15%;
  left: 25%;
}

#login > .right-login {
  flex-basis: 60%;
  height: 100vh;
  background-color: #fafafa;
  padding: 120px;
}

#login > .right-login > .auth-form {
  padding: 32px;
  padding-bottom: 10px;
  width: 100%;
}

#login > .right-login > .auth-form > h1:first-child {
  color: #0e0e0e;
  font-family: "Inter";
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: center;
}

#login > .right-login > .auth-form > .second-heading {
  color: #505050;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  padding: 5px 0;
}

form {
  width: 100%;
  padding: 30px 0;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mobile-input {
  border: 1px solid #d3d3d3;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
}

.mobile-input:focus {
  border: 1px solid #d3d3d3;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
}

.mobile-input::placeholder {
  color: #505050;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
}

#login > .right-login > .auth-form .login-button {
  background-color: #0e0e0e;
  padding: 16px;
  border-radius: 8px;
  gap: 4px;
  color: #fafafa;
}

.below-heading {
  color: #7c7c7c;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
}

.below-heading > span {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  text-decoration-line: underline;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%; /* Adjust this as needed */
  margin: 20px 0; /* Spacing around the divider */
}

.divider span {
  padding: 0;
  color: #bdbdbd;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ccc;
  margin: 0 10px;
}

.countdown-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.countdown-text > .countdown-text-heading {
  padding: 10px;
  border: 1px solid grey;
  border-radius: 10px;
  color: #fafafa;
  background-color: #0e0e0e;
}
