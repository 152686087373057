.testimonial-card {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 400px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  border: 1px solid #d8e0ff;
  background-color: #e7f0fb;
}

.testimonial-card:hover {
  background-color: #0e0e0e;
  color: #bdbdbd;
}

.testimonial-card {
  background-color: #fff;
  color: #000;
  transition: all 0.3s ease;
  transform: perspective(1000px) scale(1);

  &:hover {
    background-color: #0e0e0e;
    color: #bdbdbd;
    transform: perspective(1000px) scale(1.1);

    .testimonial-name {
      color: #bdbdbd;
    }
  }
}

.testimonial-card-content {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #d3d3d3;
}

.testimonial-image {
  border-radius: 50%;
}

.testimonial-name {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.09000000357627869px;
  text-align: center;
  color: #0e0e0e;
}

.testimonial-text {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.09000000357627869px;
  text-align: center;
}

.splide__slide {
  color: #1f2937;
  transition: transform 5s linear;
}

.splide__arrow {
  background: rgb(211, 210, 210);
  opacity: 1;
}

.splide__pagination__page {
  background: #d1d5db;
}

.splide__pagination__page.is-active {
  background: grey;
}

@media screen and (max-width: 430px) {
  .testimonial-name {
    font-size: 1rem;
  }
  .testimonial-text {
    font-size: 10px;
  }
  .testimonial-card {
    gap: 5px;
  }
}
