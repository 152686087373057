@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");

#navbar {
  width: 100%;
  padding: 48px 96px;
  background-color: transparent;
  margin-bottom: 60px;
}

.navbar-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  height: 40px;
}

.menu-container {
  display: flex;
  align-items: center;
}

.menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
  margin: 0 15px;
}

.menu a {
  text-decoration: none;
  color: #fff;
  font-family: "Inter", sans-serif;
  transition: color 0.3s ease;
}

.menu a:hover {
  color: #e93540;
}

.actions {
  display: flex;
  gap: 20px;
}

.actions button {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-family: "Inter", sans-serif;
  padding: 8px 20px;
  border-radius: 32px;
  /* font-size: 18px; */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.actions .talk-to-us {
  background-color: #fefefe;
  color: #0e0e0e;
  padding: 12px 15px;
  width: fit-content;
}

.actions .talk-to-us:hover {
  background-color: #e93540;
  color: #fff;
}

.talk-to-us:hover path {
  fill: #ffffff;
}

.login:hover {
  background-color: #fff;
  color: #0e0e0e;
}
.login:hover .contact-icon path {
  fill: #0e0e0e;
  background-color: #fff;
}

.actions img {
  width: 20px;
}

.hamburger {
  display: none;
}

@media (max-width: 1150px) {
  .navbar-content {
    flex-wrap: wrap;
  }

  .hamburger {
    display: block;
  }

  .menu-container {
    flex-direction: column;
    width: 100%;
    display: none;
    padding: 40px 0;
  }

  .menu-container.open {
    display: flex;
  }

  .menu {
    flex-direction: column;
    width: 100%;
    text-align: center;
    gap: 25px;
  }

  .menu.mobile li {
    margin: 10px 0;
  }

  .actions {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 20px;
  }

  .actions button {
    width: fit-content;
    padding: 12px 15px;
    justify-content: center;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 768px) {
  .image-resize {
    width: 150px;
  }
}

@media screen and (max-width: 490px) {
  #navbar {
    padding: 46px 10px !important;
  }
}
