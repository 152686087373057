@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");

#home-container {
  width: 100%;
  color: white;
  background-color: #e7f0fb;
}

#home {
  background-color: #0e0e0e !important;
  width: 100%;
  background-image: url("./images/bg-image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  padding-bottom: 100px;
}

#heading-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-color: transparent;
}

#heading-block > .top-tagline {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: center;
  padding: 6px 10px;
  border-radius: 30px;
  background: linear-gradient(
    90deg,
    rgba(233, 53, 63, 0.15) 0%,
    rgba(233, 53, 63, 0.0953125) 36.46%,
    rgba(233, 53, 63, 0.0460938) 69.27%,
    rgba(233, 53, 63, 0.138) 100%
  );
  background-color: transparent;
}

#heading-block > .top-tagline > span {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
}

#heading-block span {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: center;
  color: #e93540;
  background-color: transparent;
}

#heading-block > .heading {
  font-family: "Inter 24pt", sans-serif;
  font-size: 56px;
  font-weight: 500;
  line-height: 67.77px;
  text-align: center;
  background-color: transparent;
  color: rgba(254, 254, 254, 1);
}

#heading-block > .heading > span {
  font-family: "Inter 24pt", sans-serif;
  font-size: 56px;
  font-weight: 500;
  line-height: 67.77px;
  text-align: center;
  background-color: transparent;
  color: #e93540;
}

#heading-block > .sub-heading {
  color: rgba(233, 53, 63, 1);
  background-color: transparent;
  font-family: "Inter";
  font-weight: 300;
  font-size: 20px;
  line-height: 24.2px;
  text-align: center;
}

#heading-block > .invest-now-btn {
  background-color: transparent;
  transition: background-color 0.5s ease, color 0.5s ease, transform 0.5s ease;
}

#heading-block > .invest-now-btn > button {
  color: rgba(14, 14, 14, 1);
  font-family: "Inter";
  font-size: 20px;
  line-height: 24.2px;
  font-weight: 600;
  text-align: center;
  width: 288px;
  height: 64px;
  top: 461px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 1);
  border: none;
}

#heading-block > .invest-now-btn > button:hover {
  color: rgba(255, 255, 255, 1);
  background-color: #e93540;
  transform: scale(1.05);
}

#heading-block > .supporting-text {
  font-family: "Inter";
  color: rgba(102, 102, 103, 1);
  font-weight: 400;
  font-size: 12px;
  line-height: 14.52px;
  text-align: center;
}

/* two blocks of portfolio  CSS starts from here */

#portfolio1 {
  width: 95%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 24px;
  background-color: transparent;
}

#portfolio1 > .investment-portfolio1 {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 32px;
  border-radius: 16px;
  gap: 16px;
  background-color: rgba(26, 26, 29, 0.5);
}

#portfolio1 > .investment-portfolio1 > p {
  font-family: "Inter";
  font-weight: 500;
  font-size: 20px;
  line-height: 24.2px;
  text-align: center;
  background-color: transparent;
  color: rgba(222, 222, 223, 1);
  padding-bottom: 3%;
  border-bottom: 1px solid rgba(33, 33, 33, 1);
}

.investment-portfolio1 > .portfolio-table1 {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.investment-portfolio1 > .portfolio-table1 > .portfolio-col1 {
  background-color: transparent;
  padding: 0 36px;
  border-right: 1px solid rgba(98, 22, 26, 1);
}

.investment-portfolio1:last-child > .portfolio-table1 {
  padding: 12.8px 0;
}

.investment-portfolio1 > .portfolio-table1 > .portfolio-col1 > p {
  background-color: transparent;
  color: rgba(222, 222, 223, 1);
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: center;
}

.investment-portfolio1 > .portfolio-table1 > .portfolio-col1 > .num {
  font-family: "Inter" sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 33.88px;
  text-align: center;
}

.investment-portfolio1:first-child
  > .portfolio-table1
  > .portfolio-col1:nth-child(3) {
  border: none;
  margin-right: -52px;
}

.investment-portfolio1 > .portfolio-table1 > .portfolio-col1:last-child {
  border: none;
}

/* 2ND SECTION STARTS FROM HERE   ---> */

#second-section {
  width: 100%;
  background-color: transparent;
  background-color: #e7f0fb;
  padding: 40px;
  padding-bottom: 100px;
}

#second-section > .heading-block {
  width: 80%;
  margin: auto;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  padding: 20px;
  margin-top: 50px;
  margin-bottom: 60px;
}

#second-section .second-section-heading {
  width: 100%;
  color: #0e0e0e;
  font-family: "Inter";
  font-size: 30px;
  font-weight: 500;
  line-height: 40.73px;
  text-align: center;
}

#second-section > .second-section-grid {
  width: 80%;
  margin: auto;
  gap: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

#second-section .second-section-card {
  width: 100%;
  border: 1px solid #d3e6ff;
  background-color: #eef4fc;
  border-radius: 15px;
  padding: 20px;
}

#second-section .second-section-card:nth-child(2) {
  position: relative;
  z-index: 1;
  top: 50px;
}

#second-section .second-section-card:nth-child(3) {
  position: relative;
  z-index: 1;
  top: 100px;
}

#second-section .second-section-card:nth-child(5) {
  position: relative;
  z-index: 1;
  top: 50px;
}

#second-section .second-section-card:nth-child(6) {
  position: relative;
  z-index: 1;
  top: 100px;
}

#second-section > .second-section-grid h1 {
  font-family: "Inter Tight";
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #242424;
}

#second-section > .second-section-grid p {
  color: #666666;
  font-family: "Inter Tight";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 20px;
}

#second-section > .second-section-grid .second-section-card-img {
  background: url("./images/card-background-img.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 180px;
}

#second-section
  > .second-section-grid
  > .second-section-card:nth-child(6)
  .second-section-card-img {
  background: url("./images/card-background-img.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
}

#second-section .second-section-card:nth-child(1) .second-section-card-img {
  height: 190px;
}
#second-section .second-section-card:nth-child(2) .second-section-card-img {
  height: 190px;
}
#second-section .second-section-card:nth-child(3) .second-section-card-img {
  height: 190px;
}
#second-section .second-section-card:nth-child(4) .second-section-card-img {
  height: 250px;
}
#second-section .second-section-card:nth-child(5) {
  height: 367px;
}
#second-section .second-section-card:nth-child(6) {
  height: 315px;
}

#second-section > .second-section-grid .second-section-card-img > img {
  position: relative;
  z-index: 1;
  top: 70px;
  right: 10px;
}

#second-section
  > .second-section-grid
  > .second-section-card:nth-child(2)
  .second-section-card-img
  > img {
  position: relative;
  z-index: 1;
  top: 25px;
  left: 1px;
}

#second-section
  > .second-section-grid
  > .second-section-card:nth-child(3)
  .second-section-card-img
  > img {
  position: relative;
  z-index: 1;
  top: 110px;
  left: 110px;
}

#second-section
  > .second-section-grid
  > .second-section-card:nth-child(4)
  .second-section-card-img
  > img {
  position: relative;
  z-index: 1;
  top: 50px;
  left: 10px;
}

#second-section
  > .second-section-grid
  > .second-section-card:nth-child(5)
  .second-section-card-img
  > img {
  position: relative;
  z-index: 1;
  top: 10px;
  left: 10px;
}

#second-section
  > .second-section-grid
  > .second-section-card:nth-child(6)
  .second-section-card-img
  > img {
  position: relative;
  z-index: 1;
  top: 40px;
}

#third-section {
  background-color: #0e0e0e;
  padding: 10% 0;
}

#third-section > .third-section-content > p {
  width: 70%;
  margin: auto;
  font-family: "Inter";
  font-size: 40px;
  font-weight: 500;
  line-height: 48.41px;
  text-align: center;
  text-decoration-skip-ink: none;
  color: #fafafa;
}

.image-icon {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
}

.income-img:nth-child(2) > .image-icon > div:nth-child(2) {
  position: relative;
  z-index: 1;
  bottom: -32px;
}

.image-icon > div:nth-child(2) {
  position: relative;
  z-index: 1;
  bottom: -24px;
}

#third-section > .third-section-content > .income-img {
  width: 70%;
  margin: auto;
  margin-top: 40px;
  border: 2px solid white;
  text-align: left;
  border-radius: 10px;
  padding: 3% 5%;
  padding-bottom: 0;
  border: 1px solid #242424;
  background: linear-gradient(180deg, #121212 0%, #181818 100%);
  background: linear-gradient(
    47.23deg,
    rgba(233, 53, 63, 0.08) 3.53%,
    rgba(233, 53, 63, 0.008) 95.41%
  );
  background-position: right;
  background-repeat: no-repeat;
}

#third-section > .third-section-content > .income-img > div > p:first-child {
  font-family: "Inter Tight";
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #ed575f;
}

#third-section > .third-section-content > .income-img > div > p:nth-child(2) {
  font-family: "Inter Tight";
  font-size: 40px;
  font-weight: 500;
  line-height: 48.4px;
  text-align: left;
  text-decoration-skip-ink: none;
}

#third-section > .third-section-content > .income-img > div > p:last-child {
  font-family: "Inter Tight";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  text-decoration-skip-ink: none;
  color: #bdbdbd;
}

#third-section > .third-section-content > .growth-img {
  width: 70%;
  margin: auto;
  margin-top: 40px;
}

#fourth-section {
  display: flex;
  flex-direction: column;
  gap: 25px;
  background-color: #fafafa;
  width: 100%;
  padding-top: 40px;
  background-image: url(./images/4th-SECTION.svg);
}

#fourth-section > .fourth-section-top {
  width: 100%;
  margin: auto;
  margin-top: 100px;
}

.fourth-section-top .fourth-section-heading {
  width: 100%;
  margin: auto;
  font-family: "Inter";
  font-size: 40px;
  font-weight: 600;
  line-height: 48.41px;
  text-align: center;
  color: #0e0e0e;
}

.fourth-section-mid {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.fourth-section-mid > .investment-steps {
  flex-basis: 25%;
  background-color: #f1f1f1;
  border-radius: 8px;
  padding: 60px;
}

.fourth-section-mid > .investment-steps > .investment-steps-heading {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: left;
  color: black;
  padding-bottom: 20px;
}

.investment-steps-heading > span {
  color: #999999;
}

.fourth-section-mid > .investment-description {
  flex-basis: 75%;
  background-color: #fff;
  border: 1px solid #f4f4f4;
  color: black;
  border-radius: 8px;
  padding: 60px 20px;
}

.investment-steps .step-description {
  text-align: left;
}

/* Basic container */
.stepper-container {
  width: 300px;
  margin: 0 auto;
  position: relative;
}

/* Stepper that holds all the steps */
.stepper {
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Individual step */
.step {
  display: flex;
  align-items: center;
  margin: 20px 0;
  position: relative;
}

/* Step pointer (red bar) */
.step-pointer {
  width: 10px;
  height: 20px;
  background-color: red;
  position: absolute;
  left: -20px; /* Move the pointer to the left of the content */
  top: 50%; /* Center it vertically */
  transform: translateY(-50%);
  transition: background-color 0.3s ease;
}

/* Step content */
.step-content {
  font-size: 18px;
  padding-left: 30px; /* Add space to the left for the pointer */
}

/* Highlight active step */
.active .step-pointer {
  background-color: red; /* Active pointer */
}

/* Vertical line between steps */
.stepper::before {
  content: "";
  position: absolute;
  left: -15px; /* Adjust the vertical line position */
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #ddd;
}

.stepper .step:last-child::after {
  content: none;
}

.step-title {
  font-family: "Inter";
  font-size: 10px;
  font-weight: 400;
  line-height: 12.1px;
  letter-spacing: 0.16em;
  text-align: center;
  margin-bottom: 5px;
}

.step-description {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: center;
}

.fourth-section-bottom {
  width: 88%;
  margin: auto;
  margin-top: 40px;
}

.fourth-section-bottom > p {
  width: 100%;
  font-family: "Inter";
  font-size: 188px;
  font-weight: 900;
  line-height: 144px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #f1f1f1;
  text-shadow: 0px 2px 4px #ffffffdc;
}

.fractioned-money-section {
  background-image: url("./images/fractioned-money-bg.svg");
  padding: 8% 8%;
  background-color: #f9fcff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.fractioned-money-section > .content {
  margin-top: 5%;
  background-color: transparent;
  gap: 20px;
  width: 85%;
  margin: auto;
}

.fractioned-money-section > .content > .text-content > p:nth-child(1) {
  font-family: "Inter Tight";
  font-size: 40px;
  font-weight: 400;
  line-height: 50px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
}
.fractioned-money-section > .content > .text-content span {
  color: #3830a8;
  font-family: "Inter Tight";
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.fractioned-money-section > .content > .text-content > p:nth-child(2) {
  margin-top: 20px;
  font-family: "Inter Tight";
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000b2;
  border-bottom: 1px solid #3830a824;
  padding-bottom: 10px;
}

.fractioned-money-section > .content > .text-content > p:nth-child(3) {
  color: #1f1a5c;
  padding-top: 10px;
  font-family: "Inter Tight";
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.fractioned-money-section .expertise-col {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px 0;
  margin-bottom: 5%;
}

.fractioned-money-section .expertise-col > p {
  color: #1f1a5c;
  border-left: 2px solid #3830a8;
  background-color: #d8e0ff;
  padding: 5px;
  text-align: left;
  font-family: "Inter Tight";
  font-size: 18px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
  text-decoration-skip-ink: none;
  width: 40%;
  margin: auto;
}

.text-content-para {
  font-family: "Inter Tight";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000b2;
}

.fifth-section {
  width: 100%;
  margin: auto;
  padding: 80px;
  background-color: #f2f4fa;
}
.fifth-section > h1 {
  font-family: "Inter";
  font-size: 40px;
  font-weight: 600;
  line-height: 48.41px;
  text-align: center;
  color: #4d4d4d;
  width: 100%;
}

.fifth-section > h1 > span {
  color: #0e0e0e;
}

#home-testimonials {
  padding-bottom: 20px;
  border: 5px solid black;
}
.home-headings-testimonials {
  margin-bottom: 30px;
}
.h1-heading-testimonials {
  text-align: center;
  color: #0a525f;
  font-size: 24px;
  margin-top: 60px;
  font-weight: 500;
}
.h2-heading-testimonials {
  text-align: center;
  font-size: 60px;
  font-weight: 300;
  font-family: Georgia, "Times New Roman", Times, serif;
  padding-top: 20px;
}
.splide {
  height: auto;
  border: none;
}
.splide__track {
  padding: 30px;
}
.splide__list {
  overflow: visible;
}
.splide__slide {
  overflow: visible;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.homepage-testimonial-subdiv1 {
  padding: 15px;
}
.homepage-testimonial-subdiv1 img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.homepage-testimonial-subdiv2 {
  padding: 10px;
  text-align: justify;
}
.splide__slide hr {
  color: lightgray;
  width: 85%;
  margin: auto;
}

#sixth-section {
  width: 100%;
  background-color: #f4f4f5;
  padding: 80px 0;
}

#sixth-section > h1 {
  width: 85%;
  margin: auto;
  font-family: "Inter";
  font-size: 40px;
  font-weight: 600;
  line-height: 48.41px;
  text-align: center;
  color: #4d4d4d;
}

#sixth-section > h1 > span {
  color: #0e0e0e;
}

#sixth-section p {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: center;
  color: #6a6a6a;
  width: 40%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.faq-grid {
  width: 100%;
  color: #0e0e0e;
}

.sixth-section-last {
  background-color: #efefef;
  border: 0.5px solid #e9e9e9;
  width: 85%;
  margin: auto;
  margin-top: 20px;
  border-radius: 50px;
}

#sixth-section > .sixth-section-last > p {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: center;
  color: #0e0e0e;
}

#sixth-section > .sixth-section-last > p:last-child {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  margin-top: -8px;
  text-align: center;
}

#sixth-section > .sixth-section-last > p:last-child > span {
  color: #868687;
}

/* MEDIA SCREEN STARTS FROM HERE  ---> */

@media only screen and (max-width: 1333px) {
  #home-container {
    height: 1200px;
  }
}

@media only screen and (max-width: 1295px) {
  .fractioned-money-section {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1240px) {
  #second-heading-grid {
    width: 100%;
  }
  #home-container {
    height: 1200px;
  }

  .image-icon > div:nth-child(2) {
    bottom: -22px;
  }
}

@media only screen and (max-width: 1024px) {
  #home-container {
    height: 1200px;
  }
  #third-section > .explore_products {
    width: 100%;
  }

  #second-heading-grid > .box-content > .box-heading {
    font-size: 20px;
    line-height: 20px;
  }

  .third-section-flex {
    flex-direction: column;
  }

  .income-img:nth-child(2) > .image-icon > div:nth-child(2) {
    bottom: -22px;
  }

  .image-icon > div:nth-child(2) {
    bottom: -20px;
  }

  .fifth-section {
    padding: 40px;
  }

  .fifth-section > h1 {
    font-size: 36px;
    font-weight: 600;
    line-height: 42.41px;
  }
}

@media only screen and (max-width: 970px) {
  #second-section > .second-section-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  #second-section > .second-section-grid > .second-section-card:nth-child(3) {
    top: 15px;
  }
  #second-section > .second-section-grid > .second-section-card:nth-child(4) {
    position: relative;
    z-index: 10;
    top: 65px;
  }
  #second-section > .second-section-grid > .second-section-card:nth-child(5) {
    top: 35px;
  }
  #second-section > .second-section-grid > .second-section-card:nth-child(6) {
    top: 85px;
  }
  .image-icon > div:nth-child(2) {
    bottom: -15px;
  }

  .fractioned-money-section .expertise-col {
    flex-direction: column;
  }

  .fractioned-money-section .expertise-col > p {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  #heading-block > .top-tagline {
    font-size: 12px;
  }

  #heading-block > .heading {
    font-size: 38px;
    line-height: 33.88px;
  }
  #heading-block > .heading > span {
    font-size: 38px;
  }

  #heading-block > .invest-now-btn > button {
    font-size: 15px;
    line-height: 24.2px;
    width: 200px;
    height: 50px;
  }

  #portfolio1 {
    flex-direction: column;
  }

  #portfolio1 > .investment-portfolio1 > p {
    font-size: 16px;
    line-height: 24.2px;
  }

  .investment-portfolio1 > .portfolio-table1 > .portfolio-col1 > p {
    font-size: 10px;
    line-height: 14.52px;
  }

  #portfolio1 > .investment-portfolio1:nth-child(1) {
    padding: 35px 25px;
  }

  .investment-portfolio1 > .portfolio-table1 > .portfolio-col1 > .num {
    font-size: 20px;
    line-height: 33.88px;
  }

  #second-section > .heading-block > .second-section-heading {
    font-size: 24px;
    line-height: 32px;
  }

  #heading-block > .sub-heading {
    margin-top: -20px;
    font-size: 16px;
    line-height: 24.2px;
  }

  #second-heading-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  #third-section > .third-section-content > p {
    font-family: "Inter";
    font-size: 32px;
    font-weight: 500;
    line-height: 38.73px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  #third-section > .third-section-content > .income-img > div > p:first-child {
    font-size: 8px;
    font-weight: 500;
    line-height: 9.68px;
  }

  #third-section > .third-section-content > .income-img > div > p:nth-child(2) {
    font-size: 24px;
    font-weight: 500;
    line-height: 29.04px;
  }

  #third-section > .third-section-content > .income-img > div > p:last-child {
    font-size: 10px;
    font-weight: 400;
    line-height: 16.5px;
  }

  .income-img:nth-child(2) > .image-icon > div:nth-child(2) {
    bottom: -18px;
  }

  .image-icon > div:nth-child(2) {
    bottom: -13px;
  }

  .fourth-section-top .fourth-section-heading {
    font-size: 30px;
    line-height: 38.41px;
  }

  .fourth-section-mid {
    flex-direction: column;
  }

  .fourth-section-bottom > p {
    font-size: 120px;
    font-weight: 900;
    line-height: 100px;
  }

  .fractioned-money-section > .content > .text-content > p:nth-child(1) {
    font-size: 30px;
    font-weight: 400;
    line-height: 40px;
  }

  .fractioned-money-section > .content > .text-content span {
    font-size: 30px;
    line-height: 40px;
  }

  .fractioned-money-section > .content > .text-content > p:nth-child(2) {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  .fractioned-money-section > .content > .text-content > p:nth-child(3) {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
  }

  .fractioned-money-section .expertise-col > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
}

@media only screen and (max-width: 665px) {
  #home-container {
    height: 1900px;
  }
  #portfolio1 {
    flex-direction: column;
  }

  #portfolio1 > .investment-portfolio1:first-child {
    padding-left: 32px;
    padding-right: 32px;
  }

  #portfolio1 > .investment-portfolio1 > .portfolio-table1 {
    flex-direction: column;
  }

  .investment-portfolio1 > .portfolio-table1 > .portfolio-col1 {
    background-color: transparent;
    padding: 24px 24px;
    border-right-style: none;
    border-bottom: 1px solid rgba(98, 22, 26, 1);
    border-radius: 25px;
  }

  .investment-portfolio1:first-child
    > .portfolio-table1
    > .portfolio-col1:nth-child(3) {
    border: none;
    margin-right: 0;
  }

  #second-section > .heading-block {
    background: none;
  }

  .below_call_btn_heading {
    font-size: 12px;
    width: 80%;
  }

  #third-section > .explore_products > h1 {
    font-size: 32px;
  }
  .income-img:nth-child(2) > .image-icon > div:nth-child(2) {
    bottom: -15px;
  }

  .image-icon > div:nth-child(2) {
    bottom: -12px;
  }
}

@media only screen and (max-width: 570px) {
  #second-heading-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  #second-section > .heading-block {
    width: 100%;
  }
  .income-img:nth-child(2) > .image-icon > div:nth-child(2) {
    bottom: -13px;
  }

  .image-icon > div:nth-child(2) {
    bottom: -11px;
  }
  .fourth-section-bottom > p {
    font-size: 80px;
    font-weight: 900;
    line-height: 60px;
  }
}

@media only screen and (max-width: 490px) {
  #heading-block > .top-tagline {
    font-size: 11px;
    margin-bottom: -12px;
  }

  #heading-block > .heading {
    font-size: 30px;
    line-height: 35.77px;
    margin: 10px;
  }

  #heading-block > .heading > span {
    font-size: 30px;
    font-weight: 500;
    line-height: 35.77px;
  }

  #heading-block > .sub-heading {
    font-size: 15px;
  }

  #heading-block > .invest-now-btn {
    background-color: transparent;
    transition: background-color 0.5s ease, color 0.5s ease, transform 0.5s ease;
  }
  #heading-block > .invest-now-btn > button {
    font-size: 12px;
    line-height: 24.2px;
    width: 180px;
    height: 50px;
  }

  #second-section > .second-section-grid {
    width: 80%;
    margin: auto;
    gap: 30px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  #second-section > .second-section-grid > .second-section-card:nth-child(2) {
    top: 15px;
  }
  #second-section > .second-section-grid > .second-section-card:nth-child(4) {
    position: relative;
    z-index: 10;
    top: 25px;
  }
  #second-section > .second-section-grid > .second-section-card:nth-child(5) {
    top: 35px;
  }
  #second-section > .second-section-grid > .second-section-card:nth-child(6) {
    top: 45px;
  }
  .income-img:nth-child(2) > .image-icon > div:nth-child(2) {
    bottom: -12px;
  }

  .image-icon > div:nth-child(2) {
    bottom: -8px;
  }

  #third-section > .third-section-content > .income-img > div > p:first-child {
    font-size: 8px;
    font-weight: 500;
    line-height: 9.68px;
  }

  #third-section > .third-section-content > .income-img > div > p:nth-child(2) {
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
  }

  #third-section > .third-section-content > .income-img > div > p:last-child {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
  }

  .fifth-section {
    padding: 20px;
  }

  .fifth-section > h1 {
    font-size: 28px;
    font-weight: 600;
    line-height: 32.41px;
  }
}

@media only screen and (max-width: 430px) {
  #home-container {
    height: 2000px;
  }

  #third-section > .third-section-content > p {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 24.2px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .income-img:nth-child(2) > .image-icon > div:nth-child(2) {
    bottom: -9px;
  }

  .image-icon > div:nth-child(2) {
    bottom: -7px;
  }
}

@media only screen and (max-width: 345px) {
  #portfolio1 > .investment-portfolio1:first-child {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
