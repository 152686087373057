#footer-section {
  background-color: #0e0e0e;
  width: 100%;
  margin: auto;
  border: 1px solid black;
}

#footer-section > .content {
  width: 88%;
  margin: auto;
  padding: 80px 0;
  color: #999999;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
}

.investment-button-div {
  width: 100%;
  border: 1px solid #e9353f;
  background: linear-gradient(
    90deg,
    #e9353f 0%,
    rgba(255, 92, 101, 0.952941) 100%
  );
  margin-top: 30px;
  border-radius: 16px;
}

.investment-button-div > div {
  width: 88%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5%;
  padding: 30px 0;
}

.investment-button-div > div > p {
  width: 100%;
  color: #ffffff;
  font-family: "Inter";
  font-size: 50px;
  font-weight: 600;
  line-height: 60.5px;
  text-align: left;
}

.investment-button-div .investment-button {
  background-color: #0e0e0e;
  border-radius: 50px;
  justify-content: space-around;
  width: 40%;
  margin: auto;
  height: 64px;
}

.investment-button-div .investment-button > button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: center;
  color: #fefefe;
}

.investment-button-div .investment-button > button .arrow-path {
  stroke: #ffffff;
}

.investment-button:hover {
  background-color: #fefefe;
  transition: background-color 500ms ease, color 500ms ease,
    transform 500ms ease;
}

.investment-button-div .investment-button > button:hover {
  flex-direction: row-reverse;
  color: #0e0e0e;
}

.investment-button-div .investment-button > button:hover .arrow-path {
  stroke: #0e0e0e;
}

.investment-button-div .investment-button > button button:hover {
  background-color: #ffffff;
}

.footer-links {
  width: 100%;
  margin: 60px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0 20px;
  align-items: center;
  gap: 10px;
}

.first-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 27px;
  font-family: "Inter" 24pt;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
}

.first-col > .first-col-first-div {
  width: 200%;
}

.first-col-first-div img {
  width: 100%;
}

.first-col-second-div {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-around;
  align-items: center;
  background-color: #4d4d4d;
  border: 1px solid #adadae;
  padding: 5px;
  border-radius: 45px;
}

.first-col-third-div {
  width: 100%;
}

.second-col {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 50px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
}

.second-col > .link {
  text-decoration: none;
  color: #adadae;
}

.third-col {
  width: 22%;
  font-family: "Inter" 24pt;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

#copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #adadae;
  border-top: 1px solid #4d4d4d;
  padding: 20px 0;
}

@media only screen and (max-width: 1240px) {
  .first-col {
    flex-direction: column;
    text-align: center;
    width: 20%;
    gap: 10px;
  }

  .first-col-second-div {
    width: 70%;
  }
  .second-col {
    flex-direction: column;
    gap: 10px;
  }

  .third-col {
    flex-direction: column;
  }

  .first-col-first-div {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .first-col-second-div {
    width: 80%;
  }
  .investment-button-div > div > p {
    font-size: 30px;
    line-height: 30.5px;
  }
  .investment-button {
    width: 60% !important;
  }

  .investment-button-div .investment-button > button {
    font-size: 18px;
  }
}

@media only screen and (max-width: 490px) {
  .third-col {
    text-align: center;
  }

  .investment-button-div > div > p {
    font-size: 30px;
    line-height: 30.5px;
  }

  .investment-button {
    width: 80% !important;
  }

  .investment-button-div .investment-button > button {
    font-size: 10px;
  }
  .footer-links {
    flex-direction: column;
    gap: 30px;
  }

  .first-col > .first-col-first-div {
    width: 200%;
  }

  .first-col > .first-col-second-div {
    width: 140%;
  }
}
